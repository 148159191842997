<template>
    <div class="manager-sales-list list-page">
        <div class="inner">
            <div class="header">
                <div class="title"></div>
                <div class="button-group">
                    <button-wrapper
                        type="modal"
                        :width="100"
                        color="normal"
                        @click="updateCreateSalesPopUpState(true)"
                    >
                        新增PJP
                    </button-wrapper>
                    <button-wrapper
                        type="modal"
                        :width="100"
                        color="normal"
                        @click="updateTransferCasePopUpState(true)"
                    >
                        轉移文章
                    </button-wrapper>
                    <button-wrapper
                        type="modal"
                        :width="100"
                        color="normal"
                        :disabled="!isSelectionChecked"
                        @click="onBulkDeactivate"
                    >
                        停用
                    </button-wrapper>
                    <button-wrapper
                        type="modal"
                        :width="100"
                        color="normal"
                        :disabled="!isSelectionChecked"
                        @click="onBulkRenewToken"
                    >
                        Renew-Token
                    </button-wrapper>
                </div>
            </div>
            <div class="display-section">
                <div class="toolbar">
                    <div class="keyword-search">
                        <el-input
                            v-model="searchKeyword"
                            placeholder="搜尋關鍵字"
                            suffix-icon="el-icon-search"
                            @change="onTextFilterChange"
                        ></el-input>
                    </div>
                </div>
                <el-table
                    empty-text="暫無資料"
                    :data="list"
                    height="85%"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="name" label="PJP" width="100">
                    </el-table-column>
                    <el-table-column prop="email" label="登入信箱">
                    </el-table-column>
                    <el-table-column prop="caseTotal" label="案例數量">
                    </el-table-column>

                    <el-table-column width="250">
                        <template class="button-group" slot-scope="scope">
                            <div class="button-group">
                                <div
                                    class="manager-edit-button"
                                    @click="onEdit(scope.row)"
                                >
                                    編輯
                                </div>
                                <div
                                    class="manager-normal-button"
                                    @click="onDeactivate(scope.row)"
                                >
                                    停用
                                </div>
                                <div
                                    class="manager-normal-button"
                                    @click="onRenewToken(scope.row)"
                                >
                                    Renew-Token
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <infinite-loading
                        slot="append"
                        :identifier="infiniteId"
                        @infinite="handleInfinite"
                        force-use-infinite-wrapper=".el-table__body-wrapper"
                    >
                    </infinite-loading>
                </el-table>
            </div>
            <div class="policy-box">
                <div
                    class="item reveal-information"
                    @click="onOpenRevealInformationPopup"
                >
                    羅氏揭露訊息
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    data() {
        return {
            multipleSelection: [],
            searchKeyword: '',
        };
    },
    computed: {
        ...mapState({
            list: (state) => state.manager.salesList,
            infiniteId: (state) => state.manager.infiniteId,
        }),
        isSelectionChecked() {
            return this.multipleSelection.length !== 0;
        },
    },
    destroyed() {
        this.clear();
    },
    methods: {
        ...mapMutations({
            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',
            updateCreateSalesPopUpState: 'popup/updateCreateSalesPopUpState',
            updateTransferCasePopUpState: 'popup/updateTransferCasePopUpState',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',

            setTarget: 'manager/setTarget',
            updateFilterText: 'manager/updateFilterText',
            increasePage: 'manager/increasePage',
            clear: 'manager/clear',
        }),
        ...mapActions({
            fetchQuery: 'manager/fetchQuery',
            updateFilter: 'manager/updateFilter',
            onSearch: 'manager/onSearchSales',

            apiClient: 'api/invoke',
        }),
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        async handleInfinite($state) {
            this.fetchQuery();
            const RES = await this.onSearch();
            this.increasePage();
            if (RES) $state.loaded();
            else $state.complete();
        },
        onEdit(target) {
            this.setTarget(target);
            this.updateCreateSalesPopUpState(true);
        },
        onDeactivate(target) {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定停用這個PJP嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Account',
                    function: 'deactivate',
                    successMsg: 'PJP已停用',
                    data: [target.id],
                };
                await this.apiClient(PAYLOAD);
                this.updateFilter();
            });
        },
        onBulkDeactivate() {
            const IDS = [];
            this.multipleSelection.forEach((item) => {
                IDS.push(item.id);
            });

            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定停用這些PJP嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Account',
                    function: 'deactivate',
                    successMsg: 'PJP已停用',
                    data: IDS,
                };
                await this.apiClient(PAYLOAD);
                this.updateFilter();
            });
        },
        onRenewToken(target) {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定Renew Token這個PJP嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Account',
                    function: 'renewToken',
                    successMsg: 'PJP已Renew Token',
                    data: [target.id],
                };
                await this.apiClient(PAYLOAD);
            });
        },
        onBulkRenewToken() {
            const IDS = [];
            this.multipleSelection.forEach((item) => {
                IDS.push(item.id);
            });

            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定Renew Token這些PJP嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Account',
                    function: 'renewToken',
                    successMsg: 'PJP已Renew Token',
                    data: IDS,
                };
                await this.apiClient(PAYLOAD);
                this.updateFilter();
            });
        },
        onTextFilterChange(text) {
            this.updateFilterText(text);
            this.updateFilter();
        },
    },
};
</script>

<style lang="scss" scoped>
.toolbar {
    justify-content: flex-end !important;
}
.button-group {
    display: flex;
    justify-content: space-between;
    > * {
        cursor: pointer;
    }
}
</style>
